import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { todayDate, todayDateRaw } from "../utils/helpers"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const BlockContent = require("@sanity/block-content-to-react")

export const CalendarEvent = ({
  event,
  index,
  todayIndex,
  setEventDescription,
  setCurrentEventTitle,
  eventDescription,
}) => {
  const [detailsOpen, setDetailsOpen] = useState(false)
  return (
    <button
      id={todayIndex === index ? "today" : ""}
      className={`button-outline w-full block list-none  mt-2 md:mt-4 px-0 py-6 calendar-list  hover:text-white
                ${
                  todayIndex === index
                    ? "bg-green-600 text-white"
                    : "hover:bg-blue-900 border-gray-200 border hover:border-transparent"
                } 
                ${event._rawDetails !== null ? "cursor-pointer" : "cursor-text"}
                
                `}
      onMouseEnter={() => {
        // setEventDescription(event._rawDetails || null)
        // setCurrentEventTitle(event.title)
      }}
      onClick={() => {
        setDetailsOpen(previousState => !previousState)
        setEventDescription(
          eventDescription !== event._rawDetails
            ? event._rawDetails || null
            : null
        )
        setCurrentEventTitle(event.title)
      }}
    >
      <div className="flex items-start">
        <div className="flex">
          <div
            className="calendar-year text-sm py-0 px-2 text-transparent text-white"
            style={{ transform: "rotate(-90deg)" }}
          >
            {event.year}
          </div>
          <div className="ml-2">{event.date}</div>
        </div>
        <div className="relative">
          <div className=" ml-4 text-xs absolute top-0 -mt-4">
            {index === todayIndex ? (
              <span className="ml-2 capitalize inline-block rounded-full px-2 bg-blue-800 text-white">
                today
              </span>
            ) : (
              ""
            )}
            {event.isUpdated ? (
              <span className="ml-2 inline-block rounded-full px-2 bg-red-700 text-white">
                updated
              </span>
            ) : (
              ""
            )}
            {event.isHoliday ? (
              <span className="ml-2 inline-block rounded-full px-2 bg-red-700 text-white">
                <span role="img" aria-labelledby="celebration">
                  🎉
                </span>
                Holiday
              </span>
            ) : (
              ""
            )}
          </div>
          <div className="ml-2 md:ml-4 text-left">
            {event.slug === null ? (
              event.title
            ) : (
              <Link
                to={`/${event.slug.current}`}
                className="in-link leading-tight text-base text-left"
              >
                {event.title}
              </Link>
            )}
            <span className="md:ml-4 text-xs md:text-base">
              {event.timeFrom !== null ? " " + event.timeFrom : ""}{" "}
              {event.timeTo !== null ? `- ${event.timeTo}` : ""}
            </span>
            {event._rawDetails !== null ? (
              <div className="inline-block">
                <div className="font-semibold px-2  hidden lg:block button-outline">
                  {" "}
                  . . .{" "}
                </div>

                {detailsOpen ? (
                  <span className="font-semibold px-2  lg:hidden">
                    {" "}
                    &uarr;{" "}
                  </span>
                ) : (
                  <span className="font-semibold px-2  lg:hidden">
                    {" "}
                    &darr;{" "}
                  </span>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {event._rawDetails !== null && detailsOpen ? (
        <div className="px-4 text-sm mt-2 lg:hidden">
          <div className="sanity-block para-only-sanity text-left">
            <BlockContent
              blocks={event._rawDetails}
              serializers={serializers}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </button>
  )
}

export default function ({ setCalendarOpen }) {
  const data = useStaticQuery(graphql`
    {
      allSanityCalendar(sort: { order: ASC, fields: date }) {
        nodes {
          title
          todayDate: date(formatString: "YYYY-MM-DD")
          year: date(formatString: "YYYY")
          date(formatString: "MMM DD")
          remaingTime: date(fromNow: true)
          timeFrom(formatString: "h:MM A")
          timeTo(formatString: "h:MM A")
          isUpdated
          _rawDetails
          isHoliday
          slug {
            current
          }
        }
      }
    }
  `)

  const [eventDescription, setEventDescription] = useState(null)
  const [currentEventTitle, setCurrentEventTitle] = useState(null)
  const calendar = data.allSanityCalendar.nodes
  const today = todayDate()
  let foundToday = false
  let todayIndex = -1
  let beforeTodayIndex = -1

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // Set the padding top of the side calendar box as the content of detalis box changes
    document.querySelector("#sideBoxCalendarDummy").style.height =
      1.5 * document.querySelector("#sideBoxCalendar").offsetHeight + "px"
    document.querySelector("#calendarEvents").style.marginTop =
      -1.5 * document.querySelector("#sideBoxCalendar").offsetTop + "px"
  }, [windowSize.width])

  function setWidthOfEventBox() {
    const totalWidth = document.querySelector("#calendarBox").offsetWidth
    const acutalCalendar = document.querySelector("#calendarEvents").offsetWidth
    document.querySelector("#sideBoxCalendar").style.width =
      totalWidth - acutalCalendar + "px"
  }

  useEffect(() => {
    setWidthOfEventBox()
  }, [windowSize.width])

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener("resize", handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Go to today
    window.scrollTo(0, 0)
    const todayFromTop = document.querySelector("#today").offsetTop
    const eventContainerFromTop = document.querySelector("#calendarEvents")
      .offsetTop
    window.scrollBy(0, todayFromTop - eventContainerFromTop)

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize)
  }, []) // Empty array ensures that effect is only run on mount

  return (
    <div className="mb-32 lg:mb-0" id="calendarBox">
      <div className="hidden lg:block sticky top-0" id="">
        <div id="sideBoxCalendarDummy">.</div>
        <div
          id="sideBoxCalendar"
          className={`pr-16 border pl-4 py-4 -my-4 -ml-4 ${
            eventDescription !== null ? "bg-blue-800 text-white" : ""
          }`}
        >
          <button
            className="lg:inline-block hidden button-outline"
            onClick={() => window.history.back()}
          >
            <span className="text-4xl xl:text-6xl  hover:text-red-500 hover:underline">
              &larr;&nbsp;Back
            </span>
          </button>
          {eventDescription === null ? (
            <p className="text-sm">
              Tip: Clicking on events with{" "}
              <span className="font-semibold"> . . . </span> at at end of event
              toggles display of more information for that event.
            </p>
          ) : (
            ""
          )}

          <div className="mt-8">
            {eventDescription !== null ? (
              <div>
                <h3 className="font-medium text-base my-2">
                  {currentEventTitle}
                </h3>
                <h4 className="text-sm font-medium text-white uppercase">
                  Details
                </h4>
                <div className="sanity-block para-only-sanity ">
                  <BlockContent
                    blocks={eventDescription}
                    serializers={serializers}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="mx-auto md:ml-auto md:mx-8 max-w-screen-sm px-4 sm:px-8 md:px-0 ">
        <div className="relative top-0 lg:hidden">
          <div
            className="flex justify-between bottom-0 right-0 bg-white py-4 fixed"
            style={{
              zIndex: "100",
            }}
          >
            <h3 className="capitalize text-gray-800 my-2 font-medium text-xl text-right no-select mx-4">
              Calendar
            </h3>
            <div className="mx-4 text-xl no-select">
              <button
                className="px-3 bg-red-700 text-white hover:bg-red-900 hover:text-white py-1 border  hover:border-transparent button-outline"
                onClick={e => {
                  window.history.back()
                }}
              >
                X
              </button>
            </div>
          </div>
        </div>

        <ul style={{ height: "auto" }} id="calendarEvents">
          {calendar.map((event, index) => {
            //  if there is event on today then display the today label
            //  else create a today calendar label just before the next event

            if (!foundToday) {
              if (today - new Date(event.todayDate) === 0) {
                foundToday = true
                todayIndex = index
              } else if (today < new Date(event.todayDate)) {
                beforeTodayIndex = index
                foundToday = true
              }
            }

            return (
              <div key={event.date + event.title}>
                {beforeTodayIndex === index ? (
                  <li
                    id="today"
                    className="border-2 border-green-600 list-none flex items-start mt-2  md:mt-4 px-8 py-6 calendar-list bg-green-600 text-white"
                  >
                    Today : {todayDateRaw()}
                  </li>
                ) : (
                  ""
                )}
                <li>
                  <CalendarEvent
                    event={event}
                    index={index}
                    setEventDescription={setEventDescription}
                    setCurrentEventTitle={setCurrentEventTitle}
                    todayIndex={todayIndex}
                    eventDescription={eventDescription}
                  />
                </li>
              </div>
            )
          })}
          {!foundToday ? (
            <li
              id="today"
              className="border-2 border-green-600 list-none flex items-start  mt-2 md:mt-4 px-8 py-6 calendar-list bg-green-600 text-white"
            >
              Today: {todayDateRaw()}
            </li>
          ) : null}
          <li className="hover:bg-blue-900 border-gray-400 border list-none flex items-start mt-2 md:mt-4 px-10 py-6 calendar-list  hover:text-white hover:border-transparent">
            End of Events
          </li>
        </ul>
      </div>
    </div>
  )
}
