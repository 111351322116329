import React from "react"
import Calendar from "../components/calendar"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default function () {
  return (
    <Layout displayCalendar={false}>
      <SEO title="Calendar"/>
      <Calendar />
    </Layout>
  )
}
